@import '~bootstrap/dist/css/bootstrap.min.css';

$hamburger-layer-color: white;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 5px;

@import '~hamburgers/_sass/hamburgers/hamburgers.scss';

@font-face {
  font-family: OpenSans-SemiBold;
  src: url("/assets/fonts/opensans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("/assets/fonts/opensans/OpenSans-Regular.ttf") format("truetype");
}

@import '~@angular/material/theming';

// Define a custom typography config that overrides the font-family as well as the
// typography levels. See https://material.angular.io/guide/typography
$custom-typography: mat-typography-config(
  $font-family: 'OpenSans-Semibold, Roboto, monospace',
  $display-1: mat-typography-level(48px, 1.33, 400, 'OpenSans-Regular'),
  $headline: mat-typography-level(40px, 1.6, 600),
  $title: mat-typography-level(32px, 2, 600),
  $subheading-2: mat-typography-level(24px, 1.34, 400),
  $subheading-1: mat-typography-level(24px, 1.34, 400, 'OpenSans-Regular'),
  $body-1: mat-typography-level(18px, 1.78, 400, 'OpenSans-Regular'),
  $body-2: mat-typography-level(18px, 1.78, 600),
  $caption: mat-typography-level(14px, 1.1, 400, 'OpenSans-Regular'),
  $input: mat-typography-level(16px, 1.1, 400)
);
@include mat-base-typography($custom-typography);

:root {
  --primary: #134d71;
  --secondary: #67d2b5;
  --light: #f7f7f7;

  --header-height: 98px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: OpenSans-Semibold, Roboto, "Helvetica Neue", sans-serif;
}

h2:after {
  content: "";
    display: block;
    width: 32px;
    height: 4px;
    background-color: var(--secondary);
}

p {
  opacity: 0.7;
  color: black;
}

a {
  color: black;
  opacity: 0.7;
  text-decoration: none;

  &:hover {
    color: black;
    text-decoration: underline;
  }
}

.mat-caption {
  color: rgba($color: #262626, $alpha: 0.5);
  text-transform: uppercase;
}

.py-6 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-7 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-7 {
  padding-top: 5rem !important;
}

.pb-7 {
  padding-bottom: 5rem !important;
}

.hidden {
	display: none;
}

button img {
  margin-left: 15px;
}

.main-menu-button:hover, .mat-stroked-button:hover {
  .mat-button-focus-overlay {
    background-color: black;
    opacity: 0.2 !important;
    z-index: -1;
  }
}

.form-control, .form-select {
  margin: 8px 0 24px;
  padding: 14px 16px;
  border-radius: 4px;
  border: solid 2px #979797;
}

.form-control:focus, 
.form-control.ng-touched, 
.form-select:focus,
.form-select.ng-touched {
  border: solid 2px #4d4d4d;
  box-shadow: none;
}

.form-control.ng-invalid:not(.ng-untouched), 
.form-select.ng-invalid:not(.ng-untouched) {
  border: solid 2px #ff0000;
}

.form-error-text {
  font-family: OpenSans-SemiBold;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-align: right;
  color: #ff0000;
  margin: -19px 0 0;
}

input, select {
  font-family: 'OpenSans-SemiBold';
  line-height: normal;
  width: 100%;
}

label {
  font-size: 14px;
  color: rgba($color: black, $alpha: 0.5);
  line-height: normal;
  width: 100%;
}

@media (max-width: 576px) {
  .container,
  .container-fluid,
  .container-sm {
    padding: 0 20px;
  }
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: white;
}

// Dialog (modal) settings
.video-dialog {
  width: 90%;
  max-width: 950px !important;
  height: 50%;

  .mat-dialog-container {
    padding: 0px;
    background-color: black;
    overflow: hidden;
  }
}

@media (max-width: 576px) {
  .video-dialog {
    height: 30%;
  }
}

.form-dialog {
  width: 400px
}

.cdk-overlay-pane.video-dialog,
.cdk-overlay-pane.form-dialog {
  position: relative !important;
}

.dialog-backdrop {
  background: rgba(0,0,0,0.6);
}

.form-dialog .mat-dialog-container {
  padding: 32px 32px 0;
}

.form-dialog .mat-dialog-content {
  margin: 0 -30px;
  padding: 0 30px;
}

@media (max-width: 576px) {
  .form-dialog .mat-dialog-container {
    padding: 20px 15px 0;
  }

  .form-dialog .mat-dialog-content {
    margin: 0 -15px;
    padding: 0 15px;
  }
}

.form-dialog button {
  margin-bottom: 32px;
  min-width: 220px;
}

@media (max-width: 576px) {
  .form-dialog button {
    margin-bottom: 20px;
    min-width: 175px;
  }
}

.close.mat-button {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 6px;
  line-height: 14px;
  font-size: 32px;
  font-weight: normal;
  min-width: auto;
  border-radius: 50%;
}
